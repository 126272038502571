<template>
  <div class="container-fluid">
    <div class="card-body">
      <div class="pl-3 pr-3 pb-4 row align-items-center justify-content-center">
        <div class="header_qr bg-primary text-white submit-button">{{$t('myCard.myQrCode')}}</div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-4">
          <h6>
            <b>{{$t('myCard.functionChangNameCard')}}</b>
          </h6>
          <h6>{{$t('myCard.functionChangNameCardContent')}}</h6>
        </div>
      </div>
      <!-- QR Code -->
      <div class="row p-3 align-items-center justify-content-center">
        <!--  -->
        <div style="border: 2px solid #707070; padding: 20px;">
        <qr-code
          :text="this.GET_CURRENT_ACCOUNT.qr_code ? this.GET_CURRENT_ACCOUNT.qr_code : ''"
          :size="174"
          color="#000000"
          bg-color="#fff"
          error-level="H"
        ></qr-code>
        </div>
        <!--  -->
      </div>

      <div class="row pl-4 pr-4 pt-2 align-items-center justify-content-center">
        <div class>
          <h6>
            <b>{{$t('myCard.myCard')}}</b>
          </h6>
          <a @click="goToUserProfile()" style="color:#26BA50; font-size: 14px; cursor: pointer;">{{$t('myCard.changeInfo')}}</a>
        </div>
      </div>

      <div class="row p-2 align-items-center justify-content-center">
        <div class="card_infor pr-2">
          <div class="avatar_user_qr">
            <avatar
              :src="this.GET_CURRENT_ACCOUNT.url_avatar == null || this.GET_CURRENT_ACCOUNT.url_avatar == '' ? this.user_info.no_avatar : this.GET_CURRENT_ACCOUNT.url_avatar"
              backgroundColor="#e5e5e5"
              color="#0097A7"
              :size="52"
            ></avatar>
          </div>
          <!-- info -->
          <div class="p-2 text-white">
            <div class="card_info_text text-left">
              <p>{{this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname}}</p>
              <p style="padding-bottom: 10px">
                {{ getCompanyAndPosition(this.GET_CURRENT_ACCOUNT.company_name, this.GET_CURRENT_ACCOUNT.company_position) }}
              </p>
              <p>{{this.GET_CURRENT_ACCOUNT.email}}</p>
              <p>{{this.GET_CURRENT_ACCOUNT.phone}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VueQRCodeComponent from "vue-qrcode-component";
import Avatar from "vue-avatar";
import functionUtils from 'utils/functionUtils';

export default {
  data() {
    return {
      user_info: {
        id: "",
        name: "",
        email: "",
        phone: null,
        company_name: "",
        qr_code: "",
        url_avatar: "",
        no_avatar: require("../../assets/avatar-default.png")
      }
    };
  },
  computed: {
    ...mapGetters(["GET_CURRENT_ACCOUNT"])
  },
  methods: {
    getCompanyAndPosition: function(company, position){
      return functionUtils.getCompanyAndPosition(company, position)
    },
    goToUserProfile: function () {
      this.$router.push({
        name: 'userProfile.title',
        params: {
            id: this.GET_CURRENT_ACCOUNT.id
        }
      })
    },
    ...mapActions(["SET_USER_DATA"])
  },
  components: {
    "qr-code": VueQRCodeComponent,
    avatar: Avatar
  }
};
</script>
<style lang="scss" scoped>
@import '../../style/color.scss';
.header_qr {
  border-radius: 25px;
  padding: 10px 45px 10px 45px;
}
/* img */
/* .circular--square {
  border-radius: 50%;
} */
.card_infor {
  display: flex;
  background: #aeaeb0;
  border-radius: 2px;
}
.avatar_user_qr {
  display: inline;
  align-self: center;
  padding: 10px 25px 10px 15px;
}
// .circular--portrait {
//   position: relative;
//   max-width: 52px;
//   max-height: 52px;
//   overflow: hidden;
//   border-radius: 50%;
//   display: inline-block;

//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
// }

// .circular--portrait img {
//   width: 100%;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
// }
.card_info_text p {
  padding: 0;
  margin: 0;
  font-size: 11pt;
}
.submit-button {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary),color-stop(0.7, $primary));
  color: #ffffff;
}
</style>